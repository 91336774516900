<template>
  <v-container fluid >
    <v-row>
      <v-col>
        <base-material-card icon="mdi-account-group">
          <template v-slot:heading>
            <h3 class="display-2">Pelaporan Kinerja Pegawai ASN</h3>
          </template>

          <v-row v-if="loading" class="mt-5">
            <v-col v-for="i in [1,2,3,4]" :key="i">
              <v-skeleton-loader
              class="mx-auto"
              min-width="400"
              type="card"
              ></v-skeleton-loader>
            </v-col>
          </v-row>

          <v-row class="mt-5">
            <v-col>
              <div class="d-flex justify-center text-center">
                <h1 class="text-h3"><strong>KURVA DISTRIBUSI PREDIKAT KINERJA PEGAWAI DENGAN CAPAIAN KINERJA ORGANISASI <br>"{{predikat.toUpperCase()}}"</strong></h1>
              </div>
              <div class="mt-5 d-flex justify-center">
                <span v-if="i == 10" style="font-size:14px" >Frekuensi Pegawai</span>
              </div>
              <div class="mt-2">
              <LineChartGenerator
                :chart-options="chartOptions"
                :chart-data="chartTemplateKurvaCapaianKinerjaOrganisasi"
                :chart-id="chartId"
                :dataset-id-key="datasetIdKey"
                :plugins="plugins"
                :css-classes="cssClasses"
                :styles="styles"
                :width="width"
                :height="height"
              />
              </div>
            </v-col>
            <v-col>
              <div class="d-flex justify-center text-center">
                <h1 class="text-h3"><strong>DATA RIL KURVA DISTRIBUSI PREDIKAT KINERJA PEGAWAI DENGAN CAPAIAN KINERJA ORGANISASI <br>"{{predikat.toUpperCase()}}"</strong></h1>
              </div>
              <div class="mt-6">
              <LineChartGenerator
                :chart-options="chartOptions"
                :chart-data="chartRilKurvaCapaianKinerjaOrganisasi"
                :chart-id="chartId"
                :dataset-id-key="datasetIdKey"
                :plugins="plugins"
                :css-classes="cssClasses"
                :styles="styles"
                :width="width"
                :height="height"
              />
              </div>
            </v-col>
          </v-row>

          <hr class="mt-10">

          <div v-if="data.length > 0">
          <!-- Triwulan I dan II -->
          <v-row class="mt-5">
            <v-col class="3">
              <div class="d-flex justify-center text-center">
                <h1 class="text-h3"><strong>Triwulan I</strong></h1>
              </div>

              <div class="d-flex justify-center">
                <div v-for="(order, index) in [[12,11,10],[4,2,1],[7,5,3],[9,8,6]]" :key="index" style="width:min-content">
                  <div class="flip-card mt-2 mr-3" v-for="i in order" :key="i" @click="sheetMsg=grid[i].keterangan;sheet=true">
                    <div v-if="i <= 9" class="flip-card-inner">
                      <!-- kartu depan -->
                      <div :class="i == 1 ? {'grey lighten-1 black--text':chosenTW1 != i, 'grey lighten-1 black--text font-weight-bold':chosenTW1 == i} : 
                      i == 2 ? {'light-green lighten-2 black--text':chosenTW1 != i, 'light-green lighten-2 black--text font-weight-bold':chosenTW1 == i} :
                      i == 3 ? {'amber lighten-3 black--text':chosenTW1 != i, 'amber lighten-3 black--text font-weight-bold':chosenTW1 == i} :
                      i == 4 ? {'light-green lighten-2 black--text':chosenTW1 != i, 'light-green lighten-2 black--text font-weight-bold':chosenTW1 == i} :
                      i == 5 ? {'orange lighten-2 black--text':chosenTW1 != i, 'orange lighten-2 black--text font-weight-bold':chosenTW1 == i} :
                      i == 6 ? {'amber lighten-3 black--text':chosenTW1 != i, 'amber lighten-3 black--text font-weight-bold':chosenTW1 == i} :
                      i == 7 ? {'orange lighten-2 black--text':chosenTW1 != i, 'orange lighten-2 black--text font-weight-bold':chosenTW1 == i} :
                      i == 8 ? {'orange lighten-2 black--text':chosenTW1 != i, 'orange lighten-2 black--text font-weight-bold':chosenTW1 == i} :
                      {'reds black--text':chosenTW1 != i, 'reds black--text font-weight-bold':chosenTW1 == i}" class="flip-card-front blue text-h3 d-flex" :style="chosenTW1 == i ? 'opacity: 100%' : 'opacity: 40%;'">
                        <span style="position:absolute;font-size:60px; top:40%;left:35%;color:rgba(255,255,255,0.3)">{{i}}</span>
                        <p style="font-size:15px" class="mx-auto my-auto bkpsdm-outlined" v-html="grid[i].syarat"></p>
                      </div>
                      <!-- kartu belakang -->
                      <div :class="i == 1 ? {'grey lighten-1 black--text':chosenTW1 != i, 'grey lighten-1 black--text font-weight-bold':chosenTW1 == i} : 
                      i == 2 ? {'light-green lighten-2 black--text':chosenTW1 != i, 'light-green lighten-2 black--text font-weight-bold':chosenTW1 == i} :
                      i == 3 ? {'amber lighten-3 black--text':chosenTW1 != i, 'amber lighten-3 black--text font-weight-bold':chosenTW1 == i} :
                      i == 4 ? {'light-green lighten-2 black--text':chosenTW1 != i, 'light-green lighten-2 black--text font-weight-bold':chosenTW1 == i} :
                      i == 5 ? {'orange lighten-2 black--text':chosenTW1 != i, 'orange lighten-2 black--text font-weight-bold':chosenTW1 == i} :
                      i == 6 ? {'amber lighten-3 black--text':chosenTW1 != i, 'amber lighten-3 black--text font-weight-bold':chosenTW1 == i} :
                      i == 7 ? {'orange lighten-2 black--text':chosenTW1 != i, 'orange lighten-2 black--text font-weight-bold':chosenTW1 == i} :
                      i == 8 ? {'orange lighten-2 black--text':chosenTW1 != i, 'orange lighten-2 black--text font-weight-bold':chosenTW1 == i} :
                      {'reds white--text':chosenTW1 != i, 'reds white--text font-weight-bold':chosenTW1 == i}" :style="chosenTW1 == i ? 'opacity: 100%' : 'opacity: 40%;'" class="flip-card-back d-flex body-1" >
                        <div class="my-auto mx-auto px-1">
                          <p style="font-size:10px" v-html="grid[i].isi" height="fit-content"></p>
                          <!-- <ul class="mb-3">
                            <li v-for="(item, index) in grid[i].isi" :key="index" v-html="item.replaceAll('\'','')"></li>
                          </ul> -->
                          <!-- <span class="red--text" v-html="grid[i].note"></span> -->
                        </div>
                      </div>
                    </div>
                    <div v-else :class="i == 12 ? 'mt-5 ml-5 d-flex justify-center' : 'mt-4 ml-5 d-flex justify-center'">
                      <span v-if="i == 10" style="font-size:14px" >Di bawah Ekspektasi</span>
                      <span v-if="i == 11" style="font-size:14px" >Sesuai Ekspektasi</span>
                      <div v-if="i == 12">
                        <h3 style="font-size:14px" ><strong>Hasil Kerja</strong></h3>
                        <span style="font-size:14px" >Di atas Ekspektasi</span>
                      </div>
                    </div>
                  </div>
                  <div class="mt-3">
                    <span v-if="index == 1" style="font-size:14px;">Di bawah Ekspektasi</span>
                    <span v-if="index == 2" style="font-size:14px;">Sesuai Ekspektasi</span>
                    <div class="d-flex justify-center">
                      <span v-if="index == 3" style="font-size:14px;">Di atas Ekspektasi</span>
                      <span class="pl-12" v-if="index == 3" style="font-size:14px;"><strong>Perilaku Kerja</strong></span>
                    </div>
                  </div>
                </div>
              </div>

              <v-card-actions class="d-flex justify-center">
                <div class="d-flex flex-column align-center" v-if="currentYear >= 2023">
                  <v-btn @click="exportPPKPNS(1)" :loading="loadingExportPPKPNSTW1" color="light-green darken-2" dark rounded width="min-content">
                    <span> <v-icon small left>mdi-file-pdf-box</v-icon> Download PPKPNS </span>
                  </v-btn>
                  <v-btn @click="exportPenilaianKinerjaPDF(1)" :loading="loadingExportPendokumentasianKinerjaTW1" color="green darken-4 white--text" rounded class="mr-0 mt-2">
                    <span> <v-icon small left>mdi-file-pdf-box</v-icon> Download Dokumen Evaluasi Kinerja PDF</span>
                  </v-btn>
                  <v-btn @click="exportPendokumentasianKinerjaPDF(1)" :loading="loadingExportPendokumentasianKinerjaTW1" color="cyan darken-4 white--text" rounded class="mr-0 mt-2">
                    <span> <v-icon small left>mdi-file-pdf-box</v-icon> Download Pendokumentasian Kinerja PDF</span>
                  </v-btn>
                  <br>
                  <v-btn @click="exportPenilaianKinerja(1)" :loading="loadingExportPenilaianKinerjaTW1" color="primary" rounded class="mr-0 mt-2">
                    <span> <v-icon small left>mdi-file-excel-box</v-icon> Download Dokumen Evaluasi Kinerja Excel</span>
                  </v-btn>
                  <v-btn @click="exportPendokumentasianKinerja(1)" :loading="loadingExportPendokumentasianKinerjaTW1" color="primary darken-1 white--text" rounded class="mr-0 mt-2">
                    <span> <v-icon small left>mdi-file-excel-box</v-icon> Download Pendokumentasian Kinerja Excel</span>
                  </v-btn>
                </div>
                <div class="d-flex flex-column align-center" v-else>
                  <v-btn @click="exportPPKPNS(1)" :loading="loadingExportPPKPNSTW1" color="cyan darken-4" dark rounded width="min-content">
                    <span> <v-icon small left>mdi-file-pdf-box</v-icon> Download PPKPNS </span>
                  </v-btn>
                  <v-btn @click="exportPenilaianKinerja(1)" :loading="loadingExportPenilaianKinerjaTW1" color="primary" rounded class="mr-0 mt-2">
                    <span> <v-icon small left>mdi-file-excel-box</v-icon> Download Dokumen Evaluasi Kinerja </span>
                  </v-btn>
                  <v-btn @click="exportPendokumentasianKinerja(1)" :loading="loadingExportPendokumentasianKinerjaTW1" color="green darken-4 white--text" rounded class="mr-0 mt-2">
                    <span> <v-icon small left>mdi-file-excel-box</v-icon> Download Pendokumentasian Kinerja </span>
                  </v-btn>
                  <!-- <v-btn v-if="isJF && open" @click="exportKonversiNilai(1)" :loading="loadingExportKonversiNilaiTW1" color="error darken-4 white--text" rounded class="mr-0 mt-2">
                    <span> <v-icon small left>mdi-file-pdf-box</v-icon> Download Konversi Nilai Angka Kredit </span>
                  </v-btn> -->
                </div>
              </v-card-actions>

            </v-col>
            <v-col class="3">
              <div class="d-flex justify-center text-center">
                <h1 class="text-h3"><strong>Triwulan II</strong></h1>
              </div>

              <div class="d-flex justify-center">
                <div v-for="(order, index) in [[12,11,10],[4,2,1],[7,5,3],[9,8,6]]" :key="index" style="width:min-content">
                  <div class="flip-card mt-2 mr-3" v-for="i in order" :key="i" @click="sheetMsg=grid[i].keterangan;sheet=true">
                    <div v-if="i <= 9" class="flip-card-inner">
                      <!-- kartu depan -->
                      <div :class="i == 1 ? {'grey lighten-1 black--text':chosenTW2 != i, 'grey lighten-1 black--text font-weight-bold':chosenTW2 == i} : 
                        i == 2 ? {'light-green lighten-2 black--text':chosenTW2 != i, 'light-green lighten-2 black--text font-weight-bold':chosenTW2 == i} :
                        i == 3 ? {'amber lighten-3 black--text':chosenTW2 != i, 'amber lighten-3 black--text font-weight-bold':chosenTW2 == i} :
                        i == 4 ? {'light-green lighten-2 black--text':chosenTW2 != i, 'light-green lighten-2 black--text font-weight-bold':chosenTW2 == i} :
                        i == 5 ? {'orange lighten-2 black--text':chosenTW2 != i, 'orange lighten-2 black--text font-weight-bold':chosenTW2 == i} :
                        i == 6 ? {'amber lighten-3 black--text':chosenTW2 != i, 'amber lighten-3 black--text font-weight-bold':chosenTW2 == i} :
                        i == 7 ? {'orange lighten-2 black--text':chosenTW2 != i, 'orange lighten-2 black--text font-weight-bold':chosenTW2 == i} :
                        i == 8 ? {'orange lighten-2 black--text':chosenTW2 != i, 'orange lighten-2 black--text font-weight-bold':chosenTW2 == i} :
                      {'reds black--text':chosenTW2 != i, 'reds black--text font-weight-bold':chosenTW2 == i}" :style="chosenTW2 == i ? 'opacity: 100%' : 'opacity: 40%;'" class="flip-card-front blue text-h3 d-flex">
                        <span style="position:absolute;font-size:60px; top:40%;left:35%;color:rgba(255,255,255,0.3)">{{i}}</span>
                        <p style="font-size:15px" class="mx-auto my-auto bkpsdm-outlined" v-html="grid[i].syarat"></p>
                      </div>
                      <!-- kartu belakang -->
                      <div :class="i == 1 ? {'grey lighten-1 black--text':chosenTW2 != i, 'grey lighten-1 black--text font-weight-bold':chosenTW2 == i} : 
                        i == 2 ? {'light-green lighten-2 black--text':chosenTW2 != i, 'light-green lighten-2 black--text font-weight-bold':chosenTW2 == i} :
                        i == 3 ? {'amber lighten-3 black--text':chosenTW2 != i, 'amber lighten-3 black--text font-weight-bold':chosenTW2 == i} :
                        i == 4 ? {'light-green lighten-2 black--text':chosenTW2 != i, 'light-green lighten-2 black--text font-weight-bold':chosenTW2 == i} :
                        i == 5 ? {'orange lighten-2 black--text':chosenTW2 != i, 'orange lighten-2 black--text font-weight-bold':chosenTW2 == i} :
                        i == 6 ? {'amber lighten-3 black--text':chosenTW2 != i, 'amber lighten-3 black--text font-weight-bold':chosenTW2 == i} :
                        i == 7 ? {'orange lighten-2 black--text':chosenTW2 != i, 'orange lighten-2 black--text font-weight-bold':chosenTW2 == i} :
                        i == 8 ? {'orange lighten-2 black--text':chosenTW2 != i, 'orange lighten-2 black--text font-weight-bold':chosenTW2 == i} :
                        {'reds white--text':chosenTW2 != i, 'reds white--text font-weight-bold':chosenTW2 == i}" :style="chosenTW2 == i ? 'opacity: 100%' : 'opacity: 40%;'" class="flip-card-back d-flex darken-1 body-1" >
                        <div class="my-auto mx-auto px-1">
                          <p style="font-size:10px"  v-html="grid[i].isi" height="fit-content"></p>
                          <!-- <ul class="mb-3">
                            <li v-for="(item, index) in grid[i].isi" :key="index" v-html="item.replaceAll('\'','')"></li>
                          </ul> -->
                          <!-- <span class="red--text" v-html="grid[i].note"></span> -->
                        </div>
                      </div>
                    </div>
                    <div v-else :class="i == 12 ? 'mt-5 ml-5 d-flex justify-center' : 'mt-4 ml-5 d-flex justify-center'">
                      <span v-if="i == 10" style="font-size:14px" >Di bawah Ekspektasi</span>
                      <span v-if="i == 11" style="font-size:14px" >Sesuai Ekspektasi</span>
                      <div v-if="i == 12">
                        <h3 style="font-size:14px" ><strong>Hasil Kerja</strong></h3>
                        <span style="font-size:14px" >Di atas Ekspektasi</span>
                      </div>
                    </div>
                  </div>
                  <div class="mt-3">
                    <span v-if="index == 1" style="font-size:14px;">Di bawah Ekspektasi</span>
                    <span v-if="index == 2" style="font-size:14px;">Sesuai Ekspektasi</span>
                    <div class="d-flex justify-center">
                      <span v-if="index == 3" style="font-size:14px;">Di atas Ekspektasi</span>
                      <span class="pl-12" v-if="index == 3" style="font-size:14px;"><strong>Perilaku Kerja</strong></span>
                    </div>
                  </div>
                </div>
              </div>

              <v-card-actions class="d-flex justify-center">
                <div class="d-flex flex-column align-center" v-if="currentYear >= 2023">
                  <v-btn @click="exportPPKPNS(2)" :loading="loadingExportPPKPNSTW2" color="light-green darken-2" dark rounded width="min-content">
                    <span> <v-icon small left>mdi-file-pdf-box</v-icon> Download PPKPNS </span>
                  </v-btn>
                  <v-btn @click="exportPenilaianKinerjaPDF(2)" :loading="loadingExportPendokumentasianKinerjaTW2" color="green darken-4 white--text" rounded class="mr-0 mt-2">
                    <span> <v-icon small left>mdi-file-pdf-box</v-icon> Download Dokumen Evaluasi Kinerja PDF</span>
                  </v-btn>
                  <v-btn @click="exportPendokumentasianKinerjaPDF(2)" :loading="loadingExportPendokumentasianKinerjaTW2" color="cyan darken-4 white--text" rounded class="mr-0 mt-2">
                    <span> <v-icon small left>mdi-file-pdf-box</v-icon> Download Pendokumentasian Kinerja PDF</span>
                  </v-btn>
                  <br>
                  <v-btn @click="exportPenilaianKinerja(2)" :loading="loadingExportPenilaianKinerjaTW2" color="primary" rounded class="mr-0 mt-2">
                    <span> <v-icon small left>mdi-file-excel-box</v-icon> Download Dokumen Evaluasi Kinerja Excel</span>
                  </v-btn>
                  <v-btn @click="exportPendokumentasianKinerja(2)" :loading="loadingExportPendokumentasianKinerjaTW2" color="primary darken-1 white--text" rounded class="mr-0 mt-2">
                    <span> <v-icon small left>mdi-file-excel-box</v-icon> Download Pendokumentasian Kinerja Excel</span>
                  </v-btn>
                </div>
                <div class="d-flex flex-column align-center" v-else>
                  <v-btn @click="exportPPKPNS(2)" :loading="loadingExportPPKPNSTW2" color="cyan darken-4" dark rounded width="min-content">
                    <span> <v-icon small left>mdi-file-pdf-box</v-icon> Download PPKPNS </span>
                  </v-btn>
                  <v-btn @click="exportPenilaianKinerja(2)" :loading="loadingExportPenilaianKinerjaTW2" color="primary" rounded class="mr-0 mt-2">
                    <span> <v-icon small left>mdi-file-excel-box</v-icon> Download Dokumen Evaluasi Kinerja </span>
                  </v-btn>
                  <v-btn @click="exportPendokumentasianKinerja(2)" :loading="loadingExportPendokumentasianKinerjaTW2" color="green darken-4 white--text" rounded class="mr-0 mt-2">
                    <span> <v-icon small left>mdi-file-excel-box</v-icon> Download Pendokumentasian Kinerja </span>
                  </v-btn>
                  <!-- <v-btn v-if="isJF && open" @click="exportKonversiNilai(2)" :loading="loadingExportKonversiNilaiTW2" color="error darken-4 white--text" rounded class="mr-0 mt-2">
                    <span> <v-icon small left>mdi-file-pdf-box</v-icon> Download Konversi Nilai Angka Kredit </span>
                  </v-btn> -->
                </div>
              </v-card-actions>

            </v-col>
          </v-row>

          <hr class="mt-10">

          <!-- Triwulan III dan Iv -->
          <v-row class="mt-5">
            <v-col class="3">
              <div class="d-flex justify-center text-center">
                <h1 class="text-h3"><strong>Triwulan III</strong></h1>
              </div>

              <div class="d-flex justify-center">
                <div v-for="(order, index) in [[12,11,10],[4,2,1],[7,5,3],[9,8,6]]" :key="index" style="width:min-content">
                  <div class="flip-card mt-2 mr-3" v-for="i in order" :key="i" @click="sheetMsg=grid[i].keterangan;sheet=true">
                    <div v-if="i <= 9" class="flip-card-inner">
                      <!-- kartu depan -->
                      <div :class="i == 1 ? {'grey lighten-1 black--text':chosenTW3 != i, 'grey lighten-1 white--text font-weight-bold':chosenTW3 == i} : 
                        i == 2 ? {'light-green lighten-2 black--text':chosenTW3 != i, 'light-green lighten-2 white--text font-weight-bold':chosenTW3 == i} :
                        i == 3 ? {'amber lighten-3 black--text':chosenTW3 != i, 'amber lighten-3 white--text font-weight-bold':chosenTW3 == i} :
                        i == 4 ? {'light-green lighten-2 black--text':chosenTW3 != i, 'light-green lighten-2 white--text font-weight-bold':chosenTW3 == i} :
                        i == 5 ? {'orange lighten-2 black--text':chosenTW3 != i, 'orange lighten-2 white--text font-weight-bold':chosenTW3 == i} :
                        i == 6 ? {'amber lighten-3 black--text':chosenTW3 != i, 'amber lighten-3 white--text font-weight-bold':chosenTW3 == i} :
                        i == 7 ? {'orange lighten-2 black--text':chosenTW3 != i, 'orange lighten-2 white--text font-weight-bold':chosenTW3 == i} :
                        i == 8 ? {'orange lighten-2 black--text':chosenTW3 != i, 'orange lighten-2 white--text font-weight-bold':chosenTW3 == i} :
                        {'reds black--text':chosenTW3 != i, 'reds white--text font-weight-bold':chosenTW3 == i}" :style="chosenTW3 == i ? 'opacity: 100%' : 'opacity: 40%;'" class="flip-card-front blue text-h3 d-flex">
                        <span style="position:absolute;font-size:60px; top:40%;left:35%;color:rgba(255,255,255,0.3)">{{i}}</span>
                        <p style="font-size:15px" class="mx-auto my-auto bkpsdm-outlined" v-html="grid[i].syarat"></p>
                      </div>
                      <!-- kartu belakang -->
                      <div :class="i == 1 ? {'grey lighten-1 black--text':chosenTW3 != i, 'grey lighten-1 white--text font-weight-bold':chosenTW3 == i} : 
                        i == 2 ? {'light-green lighten-2 black--text':chosenTW3 != i, 'light-green lighten-2 white--text font-weight-bold':chosenTW3 == i} :
                        i == 3 ? {'amber lighten-3 black--text':chosenTW3 != i, 'amber lighten-3 white--text font-weight-bold':chosenTW3 == i} :
                        i == 4 ? {'light-green lighten-2 black--text':chosenTW3 != i, 'light-green lighten-2 white--text font-weight-bold':chosenTW3 == i} :
                        i == 5 ? {'orange lighten-2 black--text':chosenTW3 != i, 'orange lighten-2 white--text font-weight-bold':chosenTW3 == i} :
                        i == 6 ? {'amber lighten-3 black--text':chosenTW3 != i, 'amber lighten-3 white--text font-weight-bold':chosenTW3 == i} :
                        i == 7 ? {'orange lighten-2 black--text':chosenTW3 != i, 'orange lighten-2 white--text font-weight-bold':chosenTW3 == i} :
                        i == 8 ? {'orange lighten-2 black--text':chosenTW3 != i, 'orange lighten-2 white--text font-weight-bold':chosenTW3 == i} :
                        {'reds white--text':chosenTW3 != i, 'reds white--text font-weight-bold':chosenTW3 == i}" :style="chosenTW3 == i ? 'opacity: 100%' : 'opacity: 40%;'"
                        class="flip-card-back d-flex darken-1 body-1" >
                        <div class="my-auto mx-auto px-1">
                          <p style="font-size:10px"  v-html="grid[i].isi" height="fit-content"></p>
                          <!-- <ul class="mb-3">
                            <li v-for="(item, index) in grid[i].isi" :key="index" v-html="item.replaceAll('\'','')"></li>
                          </ul> -->
                          <!-- <span class="red--text" v-html="grid[i].note"></span> -->
                        </div>
                      </div>
                    </div>
                    <div v-else :class="i == 12 ? 'mt-5 ml-5 d-flex justify-center' : 'mt-4 ml-5 d-flex justify-center'">
                      <span v-if="i == 10" style="font-size:14px" >Di bawah Ekspektasi</span>
                      <span v-if="i == 11" style="font-size:14px" >Sesuai Ekspektasi</span>
                      <div v-if="i == 12">
                        <h3 style="font-size:14px" ><strong>Hasil Kerja</strong></h3>
                        <span style="font-size:14px" >Di atas Ekspektasi</span>
                      </div>
                    </div>
                  </div>
                  <div class="mt-3">
                    <span v-if="index == 1" style="font-size:14px;">Di bawah Ekspektasi</span>
                    <span v-if="index == 2" style="font-size:14px;">Sesuai Ekspektasi</span>
                    <div class="d-flex justify-center">
                      <span v-if="index == 3" style="font-size:14px;">Di atas Ekspektasi</span>
                      <span class="pl-12" v-if="index == 3" style="font-size:14px;"><strong>Perilaku Kerja</strong></span>
                    </div>
                  </div>
                </div>
              </div>

              <v-card-actions class="d-flex justify-center">
                <div class="d-flex flex-column align-center" v-if="currentYear >= 2023">
                  <v-btn @click="exportPPKPNS(3)" :loading="loadingExportPPKPNSTW3" color="light-green darken-2" dark rounded width="min-content">
                    <span> <v-icon small left>mdi-file-pdf-box</v-icon> Download PPKPNS </span>
                  </v-btn>
                  <v-btn @click="exportPenilaianKinerjaPDF(3)" :loading="loadingExportPendokumentasianKinerjaTW3" color="green darken-4 white--text" rounded class="mr-0 mt-2">
                    <span> <v-icon small left>mdi-file-pdf-box</v-icon> Download Dokumen Evaluasi Kinerja PDF</span>
                  </v-btn>
                  <v-btn @click="exportPendokumentasianKinerjaPDF(3)" :loading="loadingExportPendokumentasianKinerjaTW3" color="cyan darken-4 white--text" rounded class="mr-0 mt-2">
                    <span> <v-icon small left>mdi-file-pdf-box</v-icon> Download Pendokumentasian Kinerja PDF</span>
                  </v-btn>
                  <br>
                  <v-btn @click="exportPenilaianKinerja(3)" :loading="loadingExportPenilaianKinerjaTW3" color="primary" rounded class="mr-0 mt-2">
                    <span> <v-icon small left>mdi-file-excel-box</v-icon> Download Dokumen Evaluasi Kinerja Excel</span>
                  </v-btn>
                  <v-btn @click="exportPendokumentasianKinerja(3)" :loading="loadingExportPendokumentasianKinerjaTW3" color="primary darken-1 white--text" rounded class="mr-0 mt-2">
                    <span> <v-icon small left>mdi-file-excel-box</v-icon> Download Pendokumentasian Kinerja Excel</span>
                  </v-btn>
                </div>
                <div class="d-flex flex-column align-center" v-else>
                  <v-btn @click="exportPPKPNS(3)" :loading="loadingExportPPKPNSTW3" color="cyan darken-4" dark rounded width="min-content">
                    <span> <v-icon small left>mdi-file-pdf-box</v-icon> Download PPKPNS </span>
                  </v-btn>
                  <v-btn @click="exportPenilaianKinerja(3)" :loading="loadingExportPenilaianKinerjaTW3" color="primary" rounded class="mr-0 mt-2">
                    <span> <v-icon small left>mdi-file-excel-box</v-icon> Download Dokumen Evaluasi Kinerja </span>
                  </v-btn>
                  <v-btn @click="exportPendokumentasianKinerja(3)" :loading="loadingExportPendokumentasianKinerjaTW3" color="green darken-4 white--text" rounded class="mr-0 mt-2">
                    <span> <v-icon small left>mdi-file-excel-box</v-icon> Download Pendokumentasian Kinerja </span>
                  </v-btn>
                  <!-- <v-btn v-if="isJF && open" @click="exportKonversiNilai(3)" :loading="loadingExportKonversiNilaiTW3" color="error darken-4 white--text" rounded class="mr-0 mt-2">
                    <span> <v-icon small left>mdi-file-pdf-box</v-icon> Download Konversi Nilai Angka Kredit </span>
                  </v-btn> -->
                </div>
              </v-card-actions>

            </v-col>
            <v-col class="3">
              <div class="d-flex justify-center text-center">
                <h1 class="text-h3"><strong>Triwulan IV</strong></h1>
              </div>

              <div class="d-flex justify-center">
                <div v-for="(order, index) in [[12,11,10],[4,2,1],[7,5,3],[9,8,6]]" :key="index" style="width:min-content">
                  <div class="flip-card mt-2 mr-3" v-for="i in order" :key="i" @click="sheetMsg=grid[i].keterangan;sheet=true">
                    <div v-if="i <= 9" class="flip-card-inner">
                      <!-- kartu depan -->
                      <div :class="i == 1 ? {'grey lighten-1 black--text':chosenTW4 != i, 'grey lighten-1 white--text font-weight-bold':chosenTW4 == i} : 
                        i == 2 ? {'light-green lighten-2 black--text':chosenTW4 != i, 'light-green lighten-2 white--text font-weight-bold':chosenTW4 == i} :
                        i == 3 ? {'amber lighten-3 black--text':chosenTW4 != i, 'amber lighten-3 white--text font-weight-bold':chosenTW4 == i} :
                        i == 4 ? {'light-green lighten-2 black--text':chosenTW4 != i, 'light-green lighten-2 white--text font-weight-bold':chosenTW4 == i} :
                        i == 5 ? {'orange lighten-2 black--text':chosenTW4 != i, 'orange lighten-2 white--text font-weight-bold':chosenTW4 == i} :
                        i == 6 ? {'amber lighten-3 black--text':chosenTW4 != i, 'amber lighten-3 white--text font-weight-bold':chosenTW4 == i} :
                        i == 7 ? {'orange lighten-2 black--text':chosenTW4 != i, 'orange lighten-2 white--text font-weight-bold':chosenTW4 == i} :
                        i == 8 ? {'orange lighten-2 black--text':chosenTW4 != i, 'orange lighten-2 white--text font-weight-bold':chosenTW4 == i} :
                        {'reds black--text':chosenTW4 != i, 'reds white--text font-weight-bold':chosenTW4 == i}" :style="chosenTW4 == i ? 'opacity: 100%' : 'opacity: 40%;'" class="flip-card-front blue text-h3 d-flex">
                        <span style="position:absolute;font-size:60px; top:40%;left:35%;color:rgba(255,255,255,0.3)">{{i}}</span>
                        <p style="font-size:15px" class="mx-auto my-auto bkpsdm-outlined" v-html="grid[i].syarat"></p>
                      </div>
                      <!-- kartu belakang -->
                      <div :class="i == 1 ? {'grey lighten-1 black--text':chosenTW4 != i, 'grey lighten-1 white--text font-weight-bold':chosenTW4 == i} : 
                        i == 2 ? {'light-green lighten-2 black--text':chosenTW4 != i, 'light-green lighten-2 white--text font-weight-bold':chosenTW4 == i} :
                        i == 3 ? {'amber lighten-3 black--text':chosenTW4 != i, 'amber lighten-3 white--text font-weight-bold':chosenTW4 == i} :
                        i == 4 ? {'light-green lighten-2 black--text':chosenTW4 != i, 'light-green lighten-2 white--text font-weight-bold':chosenTW4 == i} :
                        i == 5 ? {'orange lighten-2 black--text':chosenTW4 != i, 'orange lighten-2 white--text font-weight-bold':chosenTW4 == i} :
                        i == 6 ? {'amber lighten-3 black--text':chosenTW4 != i, 'amber lighten-3 white--text font-weight-bold':chosenTW4 == i} :
                        i == 7 ? {'orange lighten-2 black--text':chosenTW4 != i, 'orange lighten-2 white--text font-weight-bold':chosenTW4 == i} :
                        i == 8 ? {'orange lighten-2 black--text':chosenTW4 != i, 'orange lighten-2 white--text font-weight-bold':chosenTW4 == i} :
                        {'reds white--text':chosenTW4 != i, 'reds white--text font-weight-bold':chosenTW4 == i}" class="flip-card-back d-flex darken-1 body-1" :style="chosenTW4 == i ? 'opacity: 100%' : 'opacity: 40%;'" >
                        <div class="my-auto mx-auto px-1">
                          <p style="font-size:10px"  v-html="grid[i].isi" height="fit-content"></p>
                          <!-- <ul class="mb-3">
                            <li v-for="(item, index) in grid[i].isi" :key="index" v-html="item.replaceAll('\'','')"></li>
                          </ul> -->
                          <!-- <span class="red--text" v-html="grid[i].note"></span> -->
                        </div>
                      </div>
                    </div>
                    <div v-else :class="i == 12 ? 'mt-5 ml-5 d-flex justify-center' : 'mt-4 ml-5 d-flex justify-center'">
                      <span v-if="i == 10" style="font-size:14px" >Di bawah Ekspektasi</span>
                      <span v-if="i == 11" style="font-size:14px" >Sesuai Ekspektasi</span>
                      <div v-if="i == 12">
                        <h3 style="font-size:14px" ><strong>Hasil Kerja</strong></h3>
                        <span style="font-size:14px" >Di atas Ekspektasi</span>
                      </div>
                    </div>
                  </div>
                  <div class="mt-3">
                    <span v-if="index == 1" style="font-size:14px;">Di bawah Ekspektasi</span>
                    <span v-if="index == 2" style="font-size:14px;">Sesuai Ekspektasi</span>
                    <div class="d-flex justify-center">
                      <span v-if="index == 3" style="font-size:14px;">Di atas Ekspektasi</span>
                      <span class="pl-12" v-if="index == 3" style="font-size:14px;"><strong>Perilaku Kerja</strong></span>
                    </div>
                  </div>
                </div>
              </div>

              <v-card-actions class="d-flex justify-center">
                <div class="d-flex flex-column align-center" v-if="currentYear >= 2023">
                  <v-btn @click="exportPPKPNS(4)" :loading="loadingExportPPKPNSTW4" color="light-green darken-2" dark rounded width="min-content">
                    <span> <v-icon small left>mdi-file-pdf-box</v-icon> Download PPKPNS </span>
                  </v-btn>
                  <v-btn @click="exportPenilaianKinerjaPDF(4)" :loading="loadingExportPendokumentasianKinerjaTW4" color="green darken-4 white--text" rounded class="mr-0 mt-2">
                    <span> <v-icon small left>mdi-file-pdf-box</v-icon> Download Dokumen Evaluasi Kinerja PDF</span>
                  </v-btn>
                  <v-btn @click="exportPendokumentasianKinerjaPDF(4)" :loading="loadingExportPendokumentasianKinerjaTW4" color="cyan darken-4 white--text" rounded class="mr-0 mt-2">
                    <span> <v-icon small left>mdi-file-pdf-box</v-icon> Download Pendokumentasian Kinerja PDF</span>
                  </v-btn>
                  <br>
                  <v-btn @click="exportPenilaianKinerja(4)" :loading="loadingExportPenilaianKinerjaTW4" color="primary" rounded class="mr-0 mt-2">
                    <span> <v-icon small left>mdi-file-excel-box</v-icon> Download Dokumen Evaluasi Kinerja Excel</span>
                  </v-btn>
                  <v-btn @click="exportPendokumentasianKinerja(4)" :loading="loadingExportPendokumentasianKinerjaTW4" color="primary darken-1 white--text" rounded class="mr-0 mt-2">
                    <span> <v-icon small left>mdi-file-excel-box</v-icon> Download Pendokumentasian Kinerja Excel</span>
                  </v-btn>
                </div>
                <div class="d-flex flex-column align-center" v-else>
                  <v-btn @click="exportPPKPNS(4)" :loading="loadingExportPPKPNSTW4" color="cyan darken-4" dark rounded width="min-content">
                    <span> <v-icon small left>mdi-file-pdf-box</v-icon> Download PPKPNS </span>
                  </v-btn>
                  <v-btn @click="exportPenilaianKinerja(4)" :loading="loadingExportPenilaianKinerjaTW4" color="primary" rounded class="mr-0 mt-2">
                    <span> <v-icon small left>mdi-file-excel-box</v-icon> Download Dokumen Evaluasi Kinerja </span>
                  </v-btn>
                  <v-btn @click="exportPendokumentasianKinerja(4)" :loading="loadingExportPendokumentasianKinerjaTW4" color="green darken-4 white--text" rounded class="mr-0 mt-2">
                    <span> <v-icon small left>mdi-file-excel-box</v-icon> Download Pendokumentasian Kinerja </span>
                  </v-btn>
                  <!-- <v-btn v-if="isJF && open" @click="exportKonversiNilai(4)" :loading="loadingExportKonversiNilaiTW4" color="error darken-4 white--text" rounded class="mr-0 mt-2">
                    <span> <v-icon small left>mdi-file-pdf-box</v-icon> Download Konversi Nilai Angka Kredit </span>
                  </v-btn> -->
                </div>
              </v-card-actions>

            </v-col>
          </v-row>

          <hr class="mt-10">

          <!-- Triwulan Akhir Tahun atau Tahunan -->
          <v-row class="mt-5">
            <v-col class="12">
              <div class="d-flex justify-center text-center">
                <h1 class="text-h3"><strong>Triwulan Akhir Tahun</strong></h1>
              </div>

              <div class="d-flex justify-center">
                <div v-for="(order, index) in [[12,11,10],[4,2,1],[7,5,3],[9,8,6]]" :key="index" style="width:min-content">
                  <div class="flip-card mt-2 mr-3" v-for="i in order" :key="i" @click="sheetMsg=grid[i].keterangan;sheet=true">
                    <div v-if="i <= 9" class="flip-card-inner">
                      <!-- kartu depan -->
                      <div :class="i == 1 ? {'grey lighten-1 white--text':chosenTWAkhir != i, 'grey lighten-1 white--text font-weight-bold':chosenTWAkhir == i} : 
                      i == 2 ? {'light-green lighten-2 white--text':chosenTWAkhir != i, 'light-green lighten-2 white--text font-weight-bold':chosenTWAkhir == i} :
                      i == 3 ? {'blue lighten-3 white--text':chosenTWAkhir != i, 'blue lighten-3 white--text font-weight-bold':chosenTWAkhir == i} :
                      i == 4 ? {'light-green lighten-2 white--text':chosenTWAkhir != i, 'light-green lighten-2 white--text font-weight-bold':chosenTWAkhir == i} :
                      i == 5 ? {'indigo lighten-2 white--text':chosenTWAkhir != i, 'indigo lighten-2 white--text font-weight-bold':chosenTWAkhir == i} :
                      i == 6 ? {'blue lighten-3 white--text':chosenTWAkhir != i, 'blue lighten-3 white--text font-weight-bold':chosenTWAkhir == i} :
                      i == 7 ? {'indigo lighten-2 white--text':chosenTWAkhir != i, 'indigo lighten-2 white--text font-weight-bold':chosenTWAkhir == i} :
                      i == 8 ? {'indigo lighten-2 white--text':chosenTWAkhir != i, 'indigo lighten-2 white--text font-weight-bold':chosenTWAkhir == i} :
                      {'indigo darken-4 white--text':chosenTWAkhir != i, 'indigo darken-4 white--text font-weight-bold':chosenTWAkhir == i}" class="flip-card-front blue text-h3 d-flex" :style="chosenTWAkhir == i ? 'opacity: 100%' : 'opacity: 40%;'">
                        <span style="position:absolute;font-size:60px; top:40%;left:35%;color:rgba(255,255,255,0.3)">{{i}}</span>
                        <p style="font-size:15px" class="mx-auto my-auto bkpsdm-outlined" v-html="grid[i].syarat"></p>
                      </div>
                      <!-- kartu belakang -->
                      <div :class="i == 1 ? {'grey lighten-1 white--text':chosenTWAkhir != i, 'grey lighten-1 white--text font-weight-bold':chosenTWAkhir == i} : 
                        i == 2 ? {'light-green lighten-2 white--text':chosenTWAkhir != i, 'light-green lighten-2 white--text font-weight-bold':chosenTWAkhir == i} :
                        i == 3 ? {'blue lighten-3 white--text':chosenTWAkhir != i, 'blue lighten-3 white--text font-weight-bold':chosenTWAkhir == i} :
                        i == 4 ? {'light-green lighten-2 white--text':chosenTWAkhir != i, 'light-green lighten-2 white--text font-weight-bold':chosenTWAkhir == i} :
                        i == 5 ? {'indigo lighten-2 white--text':chosenTWAkhir != i, 'indigo lighten-2 white--text font-weight-bold':chosenTWAkhir == i} :
                        i == 6 ? {'blue lighten-3 white--text':chosenTWAkhir != i, 'blue lighten-3 white--text font-weight-bold':chosenTWAkhir == i} :
                        i == 7 ? {'indigo lighten-2 white--text':chosenTWAkhir != i, 'indigo lighten-2 white--text font-weight-bold':chosenTWAkhir == i} :
                        i == 8 ? {'indigo lighten-2 white--text':chosenTWAkhir != i, 'indigo lighten-2 white--text font-weight-bold':chosenTWAkhir == i} :
                        {'indigo darken-4 white--text':chosenTWAkhir != i, 'indigo darken-4 white--text font-weight-bold':chosenTWAkhir == i}" :style="chosenTWAkhir == i ? 'opacity: 100%' : 'opacity: 40%;'"
                        class="flip-card-back d-flex darken-1 body-1" >
                        <div class="my-auto mx-auto px-1">
                          <p style="font-size:10px"  v-html="grid[i].isi" height="fit-content"></p>
                          <!-- <ul class="mb-3">
                            <li v-for="(item, index) in grid[i].isi" :key="index" v-html="item.replaceAll('\'','')"></li>
                          </ul> -->
                          <!-- <span class="red--text" v-html="grid[i].note"></span> -->
                        </div>
                      </div>
                    </div>
                    <div v-else :class="i == 12 ? 'mt-5 ml-5 d-flex justify-center' : 'mt-4 ml-5 d-flex justify-center'">
                      <span v-if="i == 10" style="font-size:14px" >Di bawah Ekspektasi</span>
                      <span v-if="i == 11" style="font-size:14px" >Sesuai Ekspektasi</span>
                      <div v-if="i == 12">
                        <h3 style="font-size:14px" ><strong>Hasil Kerja</strong></h3>
                        <span style="font-size:14px" >Di atas Ekspektasi</span>
                      </div>
                    </div>
                  </div>
                  <div class="mt-3">
                    <span v-if="index == 1" style="font-size:14px;">Di bawah Ekspektasi</span>
                    <span v-if="index == 2" style="font-size:14px;">Sesuai Ekspektasi</span>
                    <div class="d-flex justify-center">
                      <span v-if="index == 3" style="font-size:14px;">Di atas Ekspektasi</span>
                      <span class="pl-12" v-if="index == 3" style="font-size:14px;"><strong>Perilaku Kerja</strong></span>
                    </div>
                  </div>
                </div>
              </div>

              <v-card-actions class="d-flex justify-center">
                <div class="d-flex flex-column align-center" v-if="currentYear >= 2023">
                  <v-btn @click="exportPPKPNS('tahun')" :loading="loadingExportPPKPNSTahunan" color="light-green darken-2" dark rounded width="min-content">
                    <span> <v-icon small left>mdi-file-pdf-box</v-icon> Download PPKPNS </span>
                  </v-btn>
                  <v-btn @click="exportPenilaianKinerjaPDF('tahun')" :loading="loadingExportPendokumentasianKinerjaTahunan" color="green darken-4 white--text" rounded class="mr-0 mt-2">
                    <span> <v-icon small left>mdi-file-pdf-box</v-icon> Download Dokumen Evaluasi Kinerja PDF</span>
                  </v-btn>
                  <v-btn @click="exportPendokumentasianKinerjaPDF('tahun')" :loading="loadingExportPendokumentasianKinerjaTahunan" color="cyan darken-4 white--text" rounded class="mr-0 mt-2">
                    <span> <v-icon small left>mdi-file-pdf-box</v-icon> Download Pendokumentasian Kinerja PDF</span>
                  </v-btn>
                  <br>
                  <v-btn @click="exportPenilaianKinerja('tahun')" :loading="loadingExportPenilaianKinerjaTahunan" color="primary" rounded class="mr-0 mt-2">
                    <span> <v-icon small left>mdi-file-excel-box</v-icon> Download Dokumen Evaluasi Kinerja Excel</span>
                  </v-btn>
                  <v-btn @click="exportPendokumentasianKinerja('tahun')" :loading="loadingExportPendokumentasianKinerjaTahunan" color="primary darken-1 white--text" rounded class="mr-0 mt-2">
                    <span> <v-icon small left>mdi-file-excel-box</v-icon> Download Pendokumentasian Kinerja Excel</span>
                  </v-btn>
                  <v-btn v-if="isJF && open" @click="exportKonversiNilai('tahun')" :loading="loadingExportKonversiNilaiTahunan" color="error darken-4 white--text" rounded class="mr-0 mt-2">
                    <span> <v-icon small left>mdi-file-pdf-box</v-icon> Download Konversi Nilai Angka Kredit </span>
                  </v-btn>
                </div>
                <div class="d-flex flex-column align-center" v-else>
                  <v-btn @click="exportPPKPNS('tahun')" :loading="loadingExportPPKPNSTahunan" color="cyan darken-4" dark rounded width="min-content">
                    <span> <v-icon small left>mdi-file-pdf-box</v-icon> Download PPKPNS </span>
                  </v-btn>
                  <v-btn @click="exportPenilaianKinerja('tahun')" :loading="loadingExportPenilaianKinerjaTahunan" color="primary" rounded class="mr-0 mt-2">
                    <span> <v-icon small left>mdi-file-excel-box</v-icon> Download Dokumen Evaluasi Kinerja </span>
                  </v-btn>
                  <v-btn @click="exportPendokumentasianKinerja('tahun')" :loading="loadingExportPendokumentasianKinerjaTahunan" color="green darken-4 white--text" rounded class="mr-0 mt-2">
                    <span> <v-icon small left>mdi-file-excel-box</v-icon> Download Pendokumentasian Kinerja </span>
                  </v-btn>
                  <v-btn v-if="isJF && open" @click="exportKonversiNilai('tahun')" :loading="loadingExportKonversiNilaiTahunan" color="error darken-4 white--text" rounded class="mr-0 mt-2">
                    <span> <v-icon small left>mdi-file-pdf-box</v-icon> Download Konversi Nilai Angka Kredit </span>
                  </v-btn>
                </div>
              </v-card-actions>
            </v-col>
          </v-row>

          </div>

          <div v-else >
            <v-row justify="center">
              <v-col cols="4" v-if="loading == false">
                <v-alert
                color="blue-grey"
                dark
                dense
                icon="mdi-alert"
                prominent
                align="center"
              >
                Tidak ada data penilaian kinerja
              </v-alert>
              </v-col>
            </v-row>
          </div>

          <v-spacer class="mt-15"></v-spacer>

        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  // import Snackbar from '@/components/common/Snackbar'
  import store from '@/store'
  import {defaultRules} from '@/utils/lib'
  import UserService from '@/services/UserService'
  import SKPService from '@/services/SKPService'
  import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement
  } from 'chart.js'
  import _g from '@/global'
  import axios from 'axios'

  ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement
  )
  export default {
    name:"Default",
    components: {
      LineChartGenerator
    },
    props: {
      chartId: {
        type: String,
        default: 'line-chart'
      },
      datasetIdKey: {
        type: String,
        default: 'label'
      },
      width: {
        type: Number,
        default: 400
      },
      height: {
        type: Number,
        default: 400
      },
      cssClasses: {
        default: '',
        type: String
      },
      styles: {
        type: Object,
        default: () => {}
      },
      plugins: {
        type: Array,
        default: () => []
      }
    },
    data(){
      return {
        rules:{
          ...defaultRules
        },
        chosenTW1:null,
        chosenTW2:null,
        chosenTW3:null,
        chosenTW4:null,
        chosenTWAkhir:null,
        grid:{
          1:{syarat: 'Sangat Kurang',isi:'Perilaku Kerja <strong>Di bawah Ekspektasi</strong> dan Hasil Kerja <strong>Di bawah Ekspektasi</strong>'},
          2:{syarat: 'Kurang / Misconduct',isi:'Perilaku Kerja <strong>Di bawah Ekspektasi</strong> dan Hasil Kerja <strong>Sesuai Ekspektasi</strong>'},
          3:{syarat: 'Butuh Perbaikan',isi:'Perilaku Kerja <strong>Sesuai Ekspektasi</strong> dan Hasil Kerja <strong>Di bawah Ekspektasi</strong>'},
          4:{syarat: 'Kurang / Misconduct',isi:'Perilaku Kerja <strong>Di bawah Ekspektasi</strong> dan Hasil Kerja <strong>Di atas Ekspektasi</strong>'},
          5:{syarat: 'Baik',isi:'Perilaku Kerja <strong>Sesuai Ekspektasi</strong> dan Hasil Kerja <strong>Sesuai Ekspektasi</strong>'},
          6:{syarat: 'Butuh Perbaikan',isi:'Perilaku Kerja <strong>Di atas Ekspektasi</strong> dan Hasil Kerja <strong>Di bawah Ekspektasi</strong>'},
          7:{syarat: 'Baik', isi:'Perilaku Kerja <strong>Sesuai Ekspektasi</strong> dan Hasil Kerja <strong>Di atas Ekspektasi</strong>'},
          8:{syarat: 'Baik', isi:'Perilaku Kerja <strong>Di atas Ekspektasi</strong> dan Hasil Kerja <strong>Sesuai Ekspektasi</strong>'},
          9:{syarat: 'Sangat Baik', isi:'Perilaku Kerja <b>Di atas Ekspektasi</b> dan Hasil Kerja <b>Di atas Ekspektasi</b>'},
          10:{},
          11:{},
          12:{},
        },// grid
        data:{},
        loading:false,
        loadingExportPPKPNSTW1: false,
        loadingExportPenilaianKinerjaTW1: false,
        loadingExportPPKPNSTW2: false,
        loadingExportPenilaianKinerjaTW2: false,
        loadingExportPPKPNSTW3: false,
        loadingExportPenilaianKinerjaTW3: false,
        loadingExportPPKPNSTW4: false,
        loadingExportPenilaianKinerjaTW4: false,
        loadingExportPPKPNSTahunan: false,
        loadingExportPenilaianKinerjaTahunan: false,
        loadingExportPendokumentasianKinerjaTW1: false,
        loadingExportPendokumentasianKinerjaTW2: false,
        loadingExportPendokumentasianKinerjaTW3: false,
        loadingExportPendokumentasianKinerjaTW4: false,
        loadingExportPendokumentasianKinerjaTahunan: false,
        chartTemplateKurvaCapaianKinerjaOrganisasi: {
          labels: [
            'Sangat Kurang',
            'Kurang/Misconduct',
            'Butuh Perbaikan',
            'Baik',
            'Sangat Baik'
          ],
          datasets: [
            {
              label: 'Data Kurva Capaian Kinerja Organisasi Berdasarkan Template Permenpan 6/2022',
              backgroundColor: '#BF2026',
              data: [],
              cubicInterpolationMode: 'monotone',
              tension: 0.1
            }
          ]
        },
        chartRilKurvaCapaianKinerjaOrganisasi: {
          labels: [
            'Sangat Kurang',
            'Kurang/Misconduct',
            'Butuh Perbaikan',
            'Baik',
            'Sangat Baik'
          ],
          datasets: [
            {
              label: 'Data Ril Capaian Kinerja Organisasi',
              backgroundColor: 'teal',
              data: [],
              cubicInterpolationMode: 'monotone',
              tension: 0.4
            }
          ]
        },
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false
        },
        kurva_distribusi_skpd:null,
        data_ril_skpd:null,
        predikat:'-',
        loadingExportKonversiNilaiTW1: false,
        loadingExportKonversiNilaiTW2: false,
        loadingExportKonversiNilaiTW3: false,
        loadingExportKonversiNilaiTW4: false,
        loadingExportKonversiNilaiTahunan: false,
        isJF:null,
        isAdmin:false,
        open:false
      }
    },
    computed:{
      currentYear(){
        return store.state.user.current.year
      },
    },
    created(){
      // set theme from default
      this.$vuetify.theme.dark = store.state.settings.darkMode
      let primary = store.state.settings.primaryColor
      if (primary){
        this.$vuetify.theme.themes[this.isDark ? 'dark' : 'light'].primary = primary
      }
      this.load()
    },
    methods:{
      exportPPKPNS(triwulan){
        if(triwulan == 1){
          this.loadingExportPPKPNSTW1 = true
        }
        if(triwulan == 2){
          this.loadingExportPPKPNSTW2 = true
        }
        if(triwulan == 1){
          this.loadingExportPPKPNSTW3 = true
        }
        if(triwulan == 1){
          this.loadingExportPPKPNSTW4 = true
        }
        if(triwulan == 'tahun'){
          this.loadingExportPPKPNSTahunan = true
        }
        SKPService.insertPenilaianSKPPermenpan62022(triwulan).then(response=>{
          let res =response.data
          // Permenpan No 8 2021
          if(res.success){
            var current = this.$store.getters["user/current"]
            var date = new Date()
            var bulan = date.getMonth()
            var year = current.year
            var urls = ''
            if(triwulan == 'tahun'){
              urls = process.env.VUE_APP_API_URL+"/export-pdf/1/2s/?tipe=tahunan&thn="+year+"&crc="+res.crc
            }else{
              urls = process.env.VUE_APP_API_URL+"/export-pdf/1/2s/?twn="+triwulan+"&tipe=triwulan&thn="+year+"&crc="+res.crc
            }
            window.open(urls,'_blank');
          }else{
            store.commit('snackbar/setSnack',{message: res.message, color:'error'})
          }
        }).finally(()=>{
          if(triwulan == 1){
            this.loadingExportPPKPNSTW1 = false
          }
          if(triwulan == 2){
            this.loadingExportPPKPNSTW2 = false
          }
          if(triwulan == 1){
            this.loadingExportPPKPNSTW3 = false
          }
          if(triwulan == 1){
            this.loadingExportPPKPNSTW4 = false
          }
          if(triwulan == 'tahun'){
            this.loadingExportPPKPNSTahunan = false
          }
        })
      },
      exportPenilaianKinerja(triwulan){
        if(triwulan == 1){
          this.loadingExportPenilaianKinerjaTW1 = true
        }
        if(triwulan == 2){
          this.loadingExportPenilaianKinerjaTW2 = true
        }
        if(triwulan == 3){
          this.loadingExportPenilaianKinerjaTW3 = true
        }
        if(triwulan == 4){
          this.loadingExportPenilaianKinerjaTW4 = true
        }
        if(triwulan == 'tahun'){
          this.loadingExportPenilaianKinerjaTahunan = true
        }
        var current = this.$store.getters["user/current"]
        var nip = current.username //nip
        var year = current.year
        // var role = current.role
        var urls = ""
        // var base = 'https://erk-demo.rev.web.id/'
        var base = process.env.VUE_APP_API_URL
        if(base === 'https://mangbagja-demo.rev.web.id/'){
          base = 'https://erk-demo.rev.web.id/'
        }else{
          base = 'https://kinerja.bandung.go.id/'
        }
        if(triwulan != 'tahun'){
          urls = base+year+"/api/export-tahunan-permenpan-6-triwulan?nip="+nip+"&api=1&triwulan="+triwulan; //permenpan 6 2022
        }else{
          urls = base+year+"/api/export-tahunan-permenpan-6?nip="+nip+"&api=1"; //permenpan 6 2022
        }
        window.open(urls,'_blank');
        if(triwulan == 1){
          this.loadingExportPenilaianKinerjaTW1 = false
        }
        if(triwulan == 2){
          this.loadingExportPenilaianKinerjaTW2 = false
        }
        if(triwulan == 3){
          this.loadingExportPenilaianKinerjaTW3 = false
        }
        if(triwulan == 4){
          this.loadingExportPenilaianKinerjaTW4 = false
        }
        if(triwulan == 'tahun'){
          this.loadingExportPenilaianKinerjaTahunan = false
        }
      },
      exportPendokumentasianKinerja(triwulan){
        if(triwulan == 1){
          this.loadingExportPendokumentasianKinerjaTW1 = true
        }
        if(triwulan == 2){
          this.loadingExportPendokumentasianKinerjaTW2 = true
        }
        if(triwulan == 3){
          this.loadingExportPendokumentasianKinerjaTW3 = true
        }
        if(triwulan == 4){
          this.loadingExportPendokumentasianKinerjaTW4 = true
        }
        if(triwulan == 'tahun'){
          this.loadingExportPendokumentasianKinerjaTahunan = true
        }
        var current = this.$store.getters["user/current"]
        var nip = current.username //nip
        var year = current.year
        // var role = current.role
        var urls = ""
        // var base = 'https://erk-demo.rev.web.id/'
        var base = process.env.VUE_APP_API_URL
        if(base === 'https://mangbagja-demo.rev.web.id/'){
          base = 'https://erk-demo.rev.web.id/'
        }else{
          base = 'https://kinerja.bandung.go.id/'
        }
        if(triwulan != 'tahun'){
          urls = base+year+"/api/export-pendokumentasian-kinerja-tahunan-permenpan-6?nip="+nip+"&api=1&triwulan="+triwulan; //permenpan 6 2022
        }else{
          urls = base+year+"/api/export-pendokumentasian-kinerja-tahunan-permenpan-6?nip="+nip+"&api=1"; //permenpan 6 2022
        }
        window.open(urls,'_blank');
        if(triwulan == 1){
          this.loadingExportPendokumentasianKinerjaTW1 = false
        }
        if(triwulan == 2){
          this.loadingExportPendokumentasianKinerjaTW2 = false
        }
        if(triwulan == 3){
          this.loadingExportPendokumentasianKinerjaTW3 = false
        }
        if(triwulan == 4){
          this.loadingExportPendokumentasianKinerjaTW4 = false
        }
        if(triwulan == 'tahun'){
          this.loadingExportPendokumentasianKinerjaTahunan = false
        }
      },
      exportPenilaianKinerjaPDF(triwulan){
        if(triwulan == 1){
          this.loadingExportPenilaianKinerjaTW1 = true
        }
        if(triwulan == 2){
          this.loadingExportPenilaianKinerjaTW2 = true
        }
        if(triwulan == 3){
          this.loadingExportPenilaianKinerjaTW3 = true
        }
        if(triwulan == 4){
          this.loadingExportPenilaianKinerjaTW4 = true
        }
        if(triwulan == 'tahun'){
          this.loadingExportPenilaianKinerjaTahunan = true
        }
        var current = this.$store.getters["user/current"]
        var nip = current.username //nip
        var year = current.year
        // var role = current.role
        var urls = ""
        // var base = 'https://erk-demo.rev.web.id/'
        var base = process.env.VUE_APP_API_URL
        if(base === 'https://mangbagja-demo.rev.web.id/'){
          base = 'https://erk-demo.rev.web.id/'
        }else{
          base = 'https://kinerja.bandung.go.id/'
        }
        if(triwulan != 'tahun'){
          urls = base+year+"/api/export-tahunan-permenpan-6-triwulan-pdf?nip="+nip+"&api=1&triwulan="+triwulan; //permenpan 6 2022
        }else{
          urls = base+year+"/api/export-tahunan-permenpan-6-pdf?nip="+nip+"&api=1"; //permenpan 6 2022
        }
        window.open(urls,'_blank');
        if(triwulan == 1){
          this.loadingExportPenilaianKinerjaTW1 = false
        }
        if(triwulan == 2){
          this.loadingExportPenilaianKinerjaTW2 = false
        }
        if(triwulan == 3){
          this.loadingExportPenilaianKinerjaTW3 = false
        }
        if(triwulan == 4){
          this.loadingExportPenilaianKinerjaTW4 = false
        }
        if(triwulan == 'tahun'){
          this.loadingExportPenilaianKinerjaTahunan = false
        }
      },
      exportPendokumentasianKinerjaPDF(triwulan){
        if(triwulan == 1){
          this.loadingExportPendokumentasianKinerjaTW1 = true
        }
        if(triwulan == 2){
          this.loadingExportPendokumentasianKinerjaTW2 = true
        }
        if(triwulan == 3){
          this.loadingExportPendokumentasianKinerjaTW3 = true
        }
        if(triwulan == 4){
          this.loadingExportPendokumentasianKinerjaTW4 = true
        }
        if(triwulan == 'tahun'){
          this.loadingExportPendokumentasianKinerjaTahunan = true
        }
        var current = this.$store.getters["user/current"]
        var nip = current.username //nip
        var year = current.year
        // var role = current.role
        var urls = ""
        // var base = 'https://erk-demo.rev.web.id/'
        var base = process.env.VUE_APP_API_URL
        if(base === 'https://mangbagja-demo.rev.web.id/'){
          base = 'https://erk-demo.rev.web.id/'
        }else{
          base = 'https://kinerja.bandung.go.id/'
        }
        if(triwulan != 'tahun'){
          urls = base+year+"/api/export-pendokumentasian-kinerja-tahunan-permenpan-6-pdf?nip="+nip+"&api=1&triwulan="+triwulan; //permenpan 6 2022
        }else{
          urls = base+year+"/api/export-pendokumentasian-kinerja-tahunan-permenpan-6-pdf?nip="+nip+"&api=1"; //permenpan 6 2022
        }
        window.open(urls,'_blank');
        if(triwulan == 1){
          this.loadingExportPendokumentasianKinerjaTW1 = false
        }
        if(triwulan == 2){
          this.loadingExportPendokumentasianKinerjaTW2 = false
        }
        if(triwulan == 3){
          this.loadingExportPendokumentasianKinerjaTW3 = false
        }
        if(triwulan == 4){
          this.loadingExportPendokumentasianKinerjaTW4 = false
        }
        if(triwulan == 'tahun'){
          this.loadingExportPendokumentasianKinerjaTahunan = false
        }
      },
      load(){
        this.loading = true
        this.isJF = false
        this.isAdmin = false
        this.open = false
        var current = this.$store.getters["user/current"]
        var nip = current.username
        if(nip == '198508272003122001' || nip == '198707292011011002'){
          this.isAdmin = true
        }
        SKPService.getPenilaianSKPPermenpan6().then(response=>{
          let res = response.data
          if(this.currentYear >= 2023){
            this.isJF = res.is_jf
            this.open = res.open
          }
          if(res.success){
            this.data = res.data
            for (let i = 0; i < res.data.length; i++) {
              const el = res.data[i];
              if(el.triwulan == 1){
                this.chosenTW1 = el.grid
              }else if(el.triwulan == 2){
                this.chosenTW2 = el.grid
              }else if(el.triwulan == 3){
                this.chosenTW3 = el.grid
              }else if(el.triwulan == 4){
                this.chosenTW4 = el.grid
              }else{
                this.chosenTWAkhir = el.grid
              }
            }
          }else{
            store.commit('snackbar/setSnack',{message: res.message, color: res.response != undefined ? res.response : 'error' })
          }
        }).finally(()=>{
          this.loading = false
        })
        SKPService.getDataDistribusiPenilaianKinerja().then(response=>{
          let res = response.data
          if(res.success){
            this.kurva_distribusi_skpd = res.data.kurva_distribusi_skpd
            this.data_ril_skpd = res.data.data_ril_skpd
            this.chartTemplateKurvaCapaianKinerjaOrganisasi.datasets[0].data = res.data.kurva_distribusi_skpd
            this.chartRilKurvaCapaianKinerjaOrganisasi.datasets[0].data = res.data.data_ril_skpd
            this.predikat = res.data.predikat
          }else{
            store.commit('snackbar/setSnack',{message: res.message, color: res.response != undefined ? res.response : 'error' })
          }
        }).finally(()=>{
          this.loading = false
        })
      },
      exportKonversiNilai(triwulan){
        if(triwulan == 1){
          this.loadingExportKonversiNilaiTW1 = true
        }
        if(triwulan == 2){
          this.loadingExportKonversiNilaiTW2 = true
        }
        if(triwulan == 1){
          this.loadingExportKonversiNilaiTW3 = true
        }
        if(triwulan == 1){
          this.loadingExportKonversiNilaiTW4 = true
        }
        if(triwulan == 'tahun'){
          this.loadingExportKonversiNilaiTahunan = true
        }
        var current = this.$store.getters["user/current"]
        var nip = current.username
        if(this.currentYear >= 2023){
          axios.post(_g.getBaseURLAPIERK(this.currentYear, "v1/export-data-konversi-nilai-angka-kredit"),{
            api:1,
            nip: nip,
            triwulan: triwulan
          }).then(response => {
            let res =response.data
            let data =response.data.data
            if(res.success){
              var current = this.$store.getters["user/current"]
              var year = current.year
              var urls = ''
              if(triwulan == 'tahun'){
                urls = process.env.VUE_APP_API_URL+"/export-pdf/1/3/?tipe=tahunan&thn="+year+"&crc="+res.crc+"&tipe_data="+data.tipe_data1_or_data2
              }else{
                urls = process.env.VUE_APP_API_URL+"/export-pdf/1/3/?twn="+triwulan+"&tipe=triwulan&thn="+year+"&crc="+res.crc+"&tipe_data="+data.tipe_data1_or_data2
              }
              window.open(urls,'_blank');
            }else{
              store.commit('snackbar/setSnack',{message: res.message, color:'error'})
            }
          }).finally(()=>{
            if(triwulan == 1){
              this.loadingExportKonversiNilaiTW1 = false
            }
            if(triwulan == 2){
              this.loadingExportKonversiNilaiTW2 = false
            }
            if(triwulan == 3){
              this.loadingExportKonversiNilaiTW3 = false
            }
            if(triwulan == 4){
              this.loadingExportKonversiNilaiTW4 = false
            }
            if(triwulan == 'tahun'){
              this.loadingExportKonversiNilaiTahunan = false
            }
          }).catch(err => {
            var error = err.message
            store.commit('snackbar/setSnack',{message: error, color:'error'})
            if(triwulan == 1){
              this.loadingExportKonversiNilaiTW1 = false
            }
            if(triwulan == 2){
              this.loadingExportKonversiNilaiTW2 = false
            }
            if(triwulan == 3){
              this.loadingExportKonversiNilaiTW3 = false
            }
            if(triwulan == 4){
              this.loadingExportKonversiNilaiTW4 = false
            }
            if(triwulan == 'tahun'){
              this.loadingExportKonversiNilaiTahunan = false
            }
          })
        }
      }
    }
  }
</script>


<style lang="scss" scoped>
.table-grid{
  width:100%;
  border-collapse: collapse;

  td{
    max-width: 200px;
    border: 1px solid #ddd;

    &.content{
      padding:20px 5px 10px 5px;
      height:250px;
      overflow:auto;
      cursor: help;

      &:hover{
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      }
    }


    div{
      height:100%;
    }
  }

  .none {
    border:none;
  }

  .center{
    text-align:center
  }
}
.vertical{
  transform:rotate(270deg);
  // writing-mode: vertical-rl;
  // text-orientation: sideways;
}

.flip-card {
  background-color: transparent;
  width: 100px;
  height: 100px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  color: black;
  text-align:center;
  vertical-align: middle;

}

.flip-card-back {
  color: white;
  transform: rotateY(180deg);
}

.bkpsdm-outlined
{
    color: black;
    text-shadow:
    -1px -1px 0 #ddd,
    1px -1px 0 #ddd,
    -1px 1px 0 #ddd,
    1px 1px 0 #ddd;  
}
</style>
